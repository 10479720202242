<template>
  <div class="flex flex-col font-Inter px-10">
    <Loading v-if="loadingFull" />
    <div class="lg:flex lg:items-center lg:justify-between mb-3">
      <div class="flex-1 min-w-0 flex justify-between py-4">
        <div
          class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
        >
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Facture N° {{ invoice.number }}
          </h2>
        </div>
        <div>
          <el-button icon="el-icon-message" plain>Envoi email</el-button>
          <el-button
            icon="el-icon-printer"
            :loading="dowloandLoading"
            @click="exportInvoicePDF"
            plain
            >Imprimer</el-button
          >
          <el-button icon="el-icon-printer" @click="handleViewPDF" plain
            >Aperçu</el-button
          >
          <el-button
            v-if="invoice.status === 'OPEND'"
            type="primary"
            icon="el-icon-money"
            @click="handlePaymentInvoice"
            >Nouveau Paiement</el-button
          >

          <el-button
            v-if="invoice.status === 'DRAFT'"
            type="primary"
            :loading="validLoanding"
            @click="handleValidInvoice"
            >Confirmer</el-button
          >
          <el-dropdown class="mx-1" split-button @command="handleAction">
            Action
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="copy">Dupliquer </el-dropdown-item>
              <el-dropdown-item command="purchase">Créer une facture d'achat </el-dropdown-item>
              <el-dropdown-item
                :disabled="invoice.status !== 'DRAFT'"
                command="update"
              >
                Modifier
              </el-dropdown-item>

              <el-dropdown-item
                command="cancel"
                :disabled="
                  invoice.status === 'PAID' || invoice.status === 'CANCELED'
                "
                >Annuler
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="mb-10">
      <div class="flex flex-col mx-auto bg-white p-5 shadow">
        <el-tabs v-model="activeName" @tab-click="handleTab">
          <el-tab-pane label="Aperçu" name="INVOICE">
            <div v-if="activeName == 'INVOICE'">
              <div class="flex justify-between mb-4 items-start">
                <div class="flex flex-col w-1/2">
                  <h1
                    class="text-xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncate mb-8"
                  >
                    {{ invoice.contact_name }}
                  </h1>
                  <div class="flex flex-col bg-gray-100 w-2/3 p-3">
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">N°</span>
                      <span class="text-blue-500">{{
                        invoice.contact_no
                      }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Téléphone</span>
                      <span>{{ invoice.contact_phone }}</span>
                    </div>
                    <div class="flex justify-between mb-2">
                      <span class="text-gray-500">Email</span>
                      <span>{{ invoice.email }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Adresse</span>
                      <span>{{ invoice.contact_street }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500">Correspondant</span>
                      <span
                        >{{ invoice.contact_firstname }}
                        {{ invoice.contact_lastname }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="flex flex-col justify-end items-end w-1/2">
                  <div
                    v-if="invoice.status === 'OPEND'"
                    class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8 text-red-600"
                  >
                    Montant dû :
                    {{ (invoice.total - invoice.amount_to_pay) | moneyFilter }}
                    {{invoice.currency}}
                  </div>
                  <div
                    v-else
                    class="text-xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate mb-8"
                  >
                    Total :
                    {{ invoice.total | moneyFilter }}
                    {{invoice.currency}}
                  </div>

                  <div class="flex flex-col w-2/3 p-3">
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date d'émission :</span>
                      <span>{{ invoice.create_at }}</span>
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date :</span>
                      <span>{{ invoice.date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date d'echéance :</span>
                      <span>{{ invoice.due_date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Date de livraison :</span>
                      <span>{{ invoice.shipping_date }}</span>
                    </div>

                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Statut</span>
                      <Status :name="invoice.status" />
                    </div>
                    <div class="flex justify-between mb-3">
                      <span class="text-gray-500">Condition de paiement :</span>
                      <div>
                        {{ invoice.terms }}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <span class="text-gray-500 mr-3">Reférence</span>
                      <p>{{ invoice.reference }}</p>
                    </div>

                    <div class="flex justify-end mt-2">
                      <p v-if="nombreRetard > 0" class="text-red-400">
                        {{ nombreRetard }} jour(s) de retard
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="customer-white">
                <el-table
                  :data="invoice.line_items"
                  style="width: 100%"
                  class="mt-10"
                >
                  <el-table-column
                    prop="warehouse_code"
                    label="CODE ENT"
                    width="120"
                  >
                    <template slot-scope="{ row }">
                      <span class="text-blue">{{ row.warehouse_code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="article_sku"
                    label="N°ARTICLE"
                    width="160"
                  >
                  </el-table-column>

                  <el-table-column
                    prop="article_name"
                    label="DÉSIGNATION"
                    min-width="250"
                  >
                    <template slot-scope="{ row }">
                      <div class="flex flex-col justify-start">
                        <div class="mb-1">{{ row.article_name }}</div>

                        <p class="text-gray-400">{{ row.description }}</p>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="price"
                    align="right"
                    label="PRIX"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.price_sales | moneyFilter }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="quantity"
                    align="center"
                    label="QUANTITÉ"
                    width="150"
                  >
                    <template slot-scope="{ row }">
                      <div class="input-transparent">
                        <span>{{ row.quantity }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="taxe"
                    align="center"
                    label="REMISE "
                    width="120"
                  >
                    <template slot-scope="{ row }">
                      <span v-if="row.percent_discount"
                        >{{ row.rate_discount }} %</span
                      >
                      <span v-if="!row.percent_discount"
                        >{{ row.discount }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="taxe"
                    align="center"
                    label="TAXE "
                    width="80"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.percent_tax }} %</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="price"
                    align="right"
                    label="TOTAL"
                    width="180"
                  >
                    <template slot-scope="{ row }">
                      <span>{{ row.total | moneyFilter }} </span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="flex justify-between items-start mb-2 border-b my-8">
                <div class="flex flex-col w-2/3">
                  <div class="flex flex-col">
                    <span class="text-gray-500 mb-8"
                      >Note d'information sur la facture :</span
                    >
                    <p>{{ invoice.note }}</p>
                  </div>
                </div>
                <div class="flex flex-col w-1/3 justify-start">
                  <div class="flex justify-between">
                    <span>Total HT:</span>
                    <span> {{ invoice.sub_total | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between pt-2 mt-4 text-gray-500">
                    <span class="mr-4"
                      >Remise
                      <span v-if="invoice.percent_discount"
                        >({{ invoice.rate_discount }} %)</span
                      >
                      :</span
                    >
                    <span>{{ invoice.discount | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Taxe ({{ invoice.percent_tax }}%):</span>
                    <span>{{ invoice.total_tax | moneyFilter }} </span>
                  </div>
                  <div class="flex justify-between mt-4 text-gray-500">
                    <span class="mr-4">Autre coût:</span>
                    <span>{{ invoice.shipping_cost | moneyFilter }} </span>
                  </div>
                  <div
                    class="flex justify-between font-medium leading-7 text-gray-900 py-4 border-b"
                  >
                    <span>Total :</span>
                    <span>
                      {{ invoice.total | moneyFilter }}
                      {{ invoice.currency }}</span
                    >
                  </div>
                  <div
                    v-if="invoice.status === 'OPEND'"
                    class="flex justify-between text-gray-900 py-4"
                  >
                    <span>Avance :</span>
                    <span>{{ invoice.amount_to_pay | moneyFilter }} {{invoice.currency}}</span>
                  </div>
                  <div
                    v-if="invoice.status === 'OPEND'"
                    class="flex justify-between text-red-700 py-2"
                  >
                    <span>Reste à payer :</span>
                    <span
                      >{{
                        (invoice.total - invoice.amount_to_pay) | moneyFilter
                      }}
                      {{invoice.currency}}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Paiements" name="PAIEMENT">
            <div class="customer-white">
              <el-table
                :data="paiments"
                v-loading="listPaymentLoading"
                v-if="activeName == 'PAIEMENT'"
              >
                <el-table-column prop="date" label="DATE" width="170">
                  <template slot-scope="{ row }">
                    <span>{{ row.payment_date | filterTime }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="payment_no"
                  label="NUMÉRO PAIEMENT"
                  width="220"
                >
                  <template slot-scope="{ row }">
                    <router-link :to="`/sales/paiments/${row.payment_no}`">
                      <span class="text-blue-500">{{ row.payment_no }}</span>
                    </router-link>
                  </template>
                </el-table-column>
                <el-table-column label="STATUT" align="center" width="200">
                  <template slot-scope="{ row }">
                    <Status :name="row.status" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="payment_no"
                  label="COMPTE DE RECEPTION"
                  min-width="230"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.payment_account }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop="amount"
                  label="MONTANT"
                  align="right"
                  width="170"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.total | moneyFilter }} {{invoice.currency}}</span>
                  </template>
                </el-table-column>
                <el-table-column width="120" align="right">
                  <template slot-scope="{ row }">
                    <el-button
                      :loading="
                        numberPrinte === row.payment_no ? loadingPrint : false
                      "
                      size="small"
                      icon="el-icon-printer"
                      circle
                      @click="exportRecuPDF(row.payment_no)"
                    >
                    </el-button>
                    <el-button
                      size="small"
                      type="danger"
                      icon="el-icon-delete"
                      v-if="row.status === 'PAID'"
                      @click="handleAnnuler(row.payment_no)"
                      circle
                    >
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="flex justify-end">
              <pagination
                v-show="listQuery.total > 0"
                :total="listQuery.total"
                :page.sync="listQuery.page"
                :limit.sync="listQuery.size"
                @pagination="fetchData()"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <CancelPayment
      :number="numberSelecte"
      :visible="dialogVisible"
      @handleNo="handleCancel"
      @handleConfirme="handleYes"
    />
    <!-- List Payment en Bl -->
    <el-dialog
      :title="`REGLÈMENT FACTURE N° : ${invoice.number}`"
      :visible.sync="dialogPaymentInvoice"
      width="35%"
      top="4vh"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="paymentInvoice"
        :rules="rules"
        ref="paymentInvoiceForm"
        :label-position="labelPosition"
      >
        <div class="flex">
          <el-form-item
            style="width: 50%"
            class="mr-2"
            prop="payment_no"
            label="Numéro "
          >
            <el-input
              class="w-full"
              v-model="paymentInvoice.payment_no"
              :readonly="true"
            >
            </el-input>
          </el-form-item>

          <el-form-item style="width: 50%" prop="payment_date" label="Date">
            <el-date-picker
              style="width: 100%"
              class="w-full"
              v-model="paymentInvoice.payment_date"
              type="date"
              placeholder="jj/mm/aaaa"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="my-5 text-blue">
          Selectionner le compte et entrer le montant correspondant
        </div>
        <div
          class="item flex items-center"
          v-for="(item, index) in paymentInvoice.accounts"
          :key="item.account_id"
        >
          <i
            v-if="paymentInvoice.accounts.length > 1"
            @click="handleDeletePaymentItem(index)"
            style="width: 5%"
            class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
          ></i>
          <el-form-item
            class="mr-2"
            style="width: 70%"
            :prop="`accounts.${index}.account_number`"
            :rules="{
              required: true,
              message: 'Le compte est obligatoire',
              trigger: 'change',
            }"
          >
            <el-select
              v-model="item.account_number"
              prop="account_number"
              class="w-full"
              @change="handleSelectAccount(item)"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_id"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="width: 30%"
            :prop="`accounts.${index}.amount`"
            :rules="{
              required: true,
              message: 'Le montant est obligatoire',
              trigger: 'blur',
            }"
          >
            <el-input-number
              style="width: 100%"
              :controls="false"
              v-model="item.amount"
              :min="0"
            ></el-input-number>
          </el-form-item>
        </div>
        <div class="flex justify-end">
          <el-button
            v-if="paymentInvoice.accounts.length < 3"
            type="text"
            @click="handleAccount"
            >Ajouter un compte</el-button
          >
        </div>
        <el-form-item label="Note">
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 3 }"
            placeholder="Entrez quelque chose"
            v-model="paymentInvoice.note"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogPaymentInvoice = false">Annuler</el-button>
        <el-button
          type="primary"
          :disabled="paymentInvoiceLoading"
          :loading="paymentInvoiceLoading"
          @click="createPaymentInvoice('paymentInvoiceForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
    <!-- View PDF -->
    <PDFView :url="urlPDF" :visible="dialogViewPDF" @close="handleClose" />
  </div>
</template>

<script>
import { fetchAccounts } from "@/api/account";
import { exportInvoicePDF, exportRecuPDF } from "@/api/export";
import Pagination from "@/components/Pagination";
import {
  getDocument,
  getPaymentForInvoice,
  getNextPaymentNO,
  newPaymentInvoice,
  copyDocument,
  cancelDocument,
  validDocument,
} from "@/api/sales2";
import Loading from "@/components/Loanding/Full";
import CancelPayment from "@/components/CancelPayment";
import { parseDate, parseTime, getDayRetard, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import Status from "@/components/Status";
import PDFView from "@/components/PDF/view";
import {mapGetters} from "vuex";

export default {
  name: "InvoiceFiche",
  filters: {
    filterTime: parseTime,
    filterDate: parseDate,
    dayRetard: getDayRetard,
    moneyFilter: toThousandFilter,
  },
  components: { Loading, Status, CancelPayment, Pagination, PDFView },
  data() {
    return {
      typeDoc: "INVOICE",
      urlPDF: null,
      dialogViewPDF: false,
      loadingFull: false,
      dowloandLoading: false,
      validLoanding: false,
      dialogVisible: false,
      numberPrinte: "",
      numberSelecte: "",
      activeName: "INVOICE",
      dialogPaymentInvoice: false,
      labelPosition: "top",
      paymentInvoiceLoading: false,
      listPaymentLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
      },
      invoice: {
        comment: null,
        _id: "",
        account_credit_balance: 0,
        account_credit_number: "",
        account_credit_title: "",
        billing_to: "",
        contact_id: "",
        contact_name: "",
        contact_no: "",
        contact_phone: "",
        contact_street: "",
        currency: "",
        date: "",
        reference: "",
        terms: "",
        due_date: "",
        shipping_cost: 0.0,
        shipping_to: "",
        status: "",
        percent_discount: 0,
        percent_tax: 0,
        total: 0.0,
        total_tax: 0.0,
        total_discount: 0.0,
        sub_total: 0.0,
        total_profit: 0.0,
        line_items: [],
      },
      accounts: [],

      paymentInvoice: {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        account_number: "",
        type: "PAYMENT_CUSTOMER",
        accounts: [],
        invoices: [],
      },
      paiments: [],
      rules: {
        payment_date: [
          {
            required: true,
            message: "La date est obligatoire",
            trigger: "change",
          },
        ],
        payment_no: [
          {
            required: true,
            message: "La numéro paiement est obligatoire",
            trigger: "bluer",
          },
        ],
      },
    };
  },
  computed: {
    nombreRetard: function () {
      return getDayRetard(this.invoice.deadline_invoice);
    },
    totalPay: function () {
      return this.paymentInvoice.accounts.reduce(
        (total, item) => total + item.amount,
        0
      );
    },
    amountResToPay: function () {
      return this.invoice.total - this.invoice.amount_to_pay;
    },
      ...mapGetters(["user"]),
  },


  mounted() {
    this.resetInvoice();
    this.fetchData();
    this.fetchPaymentData();
    this.getAccounts();
  },
  methods: {
    exportInvoicePDF() {
      exportInvoicePDF(this.invoice._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/pdf",
            })
          );
          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = url;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getDocument(id, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            this.invoice = Object.assign({}, res.data);
            this.loadingFull = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetPayment() {
      this.paymentInvoice = {
        contact_id: "",
        note: "",
        payment_date: formatDate(new Date()),
        payment_no: "",
        amount: 0,
        account_number: "",
        type: "PAYMENT_CUSTOMER",
        accounts: [],
        invoices: [],
      };
    },
    exportRecuPDF(e) {
      this.numberPrinte = e;
      exportRecuPDF(this.numberPrinte)
        .then((res) => {
          setTimeout(() => {
            const url = window.URL.createObjectURL(
              new Blob([res], {
                type: "application/pdf",
              })
            );
            let iframe = document.createElement("iframe"); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = "none";
            iframe.src = url;
            iframe.onload = function () {
              setTimeout(function () {
                iframe.focus();
                iframe.contentWindow.print();
              }, 1);
            };
          }, 1000);
        })
        .catch((err) => {
          this.loadingPrint = false;
          console.log(err);
          console.log("hello");
        });
    },
    async fetchPaymentData() {
      const id = this.$route.params && this.$route.params.id;
      this.listPaymentLoading = true;
      await getPaymentForInvoice(id, this.listQuery)
        .then((res) => {
          setTimeout(() => {
            this.paiments = res.data.items;

            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listPaymentLoading = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCancel(e) {
      this.dialogVisible = e;
    },
    handleAnnuler(e) {
      this.numberSelecte = e;
      this.dialogVisible = true;
    },
    handleYes() {
      this.dialogVisible = false;
      const item = this.paiments.find(
        (el) => el.payment_no === this.numberSelecte
      );
      item.status = "CANCELED";
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getNextPaymentNo() {
      await getNextPaymentNO()
        .then((res) => {
          this.paymentInvoice.payment_no = res.data.number;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleSelectAccount(item) {
      const accountExistList = this.paymentInvoice.accounts.filter(
        (el) => el.account_number === item.account_number
      ).length;

      if (accountExistList > 1) {
        item.account_number = "";
        this.$message({
          message: "Veuillez selectionner un autre compte",
          type: "warning",
        });
      } else {
        const account = this.accounts.find(
          (el) => el.numero === item.account_number
        );
        item.account_name = account.account_name;
      }
    },
    handleDeletePaymentItem(index) {
      this.paymentInvoice.accounts.splice(index, 1);
    },
    handleAccount() {
      this.paymentInvoice.accounts.push({
        account_number: "",
        account_name: "",
        amount: 0,
      });
    },
    handlePaymentInvoice() {
      this.resetPayment();
      this.paymentInvoice.contact_id = this.invoice.contact_id;
      const accountDefault = this.accounts.find(
        (el) => el.has_primary === true
      );
      this.getNextPaymentNo();
      this.paymentInvoice.accounts.push({
        account_number: accountDefault.numero,
        account_name: accountDefault.account_name,
        amount: this.invoice.total - this.invoice.amount_to_pay,
      });
      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );
      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }
      if (
        this.invoice.account_credit_number != null &&
        this.invoice.account_credit_balance > 0
      ) {
        this.accounts.unshift({
          numero: this.invoice.account_credit_number,
          account_name: this.invoice.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }

      this.dialogPaymentInvoice = true;
    },

    createPaymentInvoice(paymentInvoiceForm) {
      this.$refs[paymentInvoiceForm].validate((valid) => {
        if (valid) {
          const exitAmountZero = this.paymentInvoice.accounts.filter(
            (el) => el.amount === 0
          ).length;

          if (this.totalPay > this.amountResToPay) {
            this.$message({
              showClose: false,
              message: "Les montant restant à payer est different total saisi",
              type: "error",
            });
          } else if (exitAmountZero > 0) {
            this.$message({
              showClose: false,
              message: "Le montant ne peut être égal 0",
              type: "error",
            });
          } else {
            this.paymentInvoiceLoading = true;
            this.paymentInvoice.invoices = [];
            // this.paymentBill.accounts.amount = this.paymentBill.amount;
            this.paymentInvoice.invoices = [];

            this.paymentInvoice.invoices.push({
              _id: this.invoice._id,
              amount: this.totalPay,
            });

            newPaymentInvoice(this.paymentInvoice, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  this.invoice.amount_to_pay += this.totalPay;
                  if (
                    this.invoice.amount_to_pay === this.invoice.total_all_taxe
                  ) {
                    this.invoice.status = "PAID";
                  }

                  //this.fetchData();
                  this.paymentInvoiceLoading = false;
                  this.dialogPaymentInvoice = false;
                  this.$message({
                    showClose: true,
                    message: "Le paiement enregistré.",
                    type: "success",
                  });
                  //  this.$router.push(`/purchase/invoice/${res.data}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                this.paymentInvoiceLoading = false;
                if (err.data.status === 400) {
                  this.$message({
                    message:
                      "Veuillez renseigner les informations obligatoire et les articles",
                    type: "warning",
                  });
                } else if (err.data.status === 409 || err.data.status === 404) {
                  this.$message({
                    message: err.data.message,
                    type: "danger",
                  });
                } else {
                  this.$message({
                    message:
                      "Erreur serveur ! Merci de contacter l'administrateur",
                    type: "danger",
                  });
                }
              });
          }
        } else {
          console.log("hello");
        }
      });
    },
    resetInvoice() {
      this.invoice = {
        comment: null,
        invoice_id: "",
        contact_id: "",
        contact_first_name: "",
        contact_last_name: "",
        contact_organisation_name: "",
        invoice_no: "",
        reference: "",
        deadline_invoice: "",
        shipping_charge: 0.0,
        status_invoice: "",
        amount_discount: 0.0,
        amount_tva: 0.0,
        tatal: 0.0,
        total_wt: 0.0,
        order_no: null,
        items: [],
      };
    },
    handleTab(obj) {
      const name = obj.name;

      switch (name) {
        case "INVOICE":
          this.fetchData();
          break;

        case "PAIEMENT":
          this.fetchPaymentData();
          break;
      }
    },

    handleCancelInvoice() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.invoice.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment annuler la facture  "),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            cancelDocument(this.invoice._id, this.typeDoc)
              .then(() => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.invoice.status = "CANCELED";

                  this.$message({
                    type: "success",
                    message: "La facture a été annulée",
                  });
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleCopyInvoice() {
      const h = this.$createElement;
      this.$msgbox({
        title: "Facture N° " + this.invoice.number,
        message: h("div", null, [
          h("span", null, " Voulez vous vraiment dupliquer la facture  "),
        ]),
        showCancelButton: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "encours...";
            copyDocument(this.invoice._id, this.typeDoc)
              .then((res) => {
                setTimeout(() => {
                  done();
                  setTimeout(() => {
                    instance.confirmButtonLoading = false;
                  }, 300);

                  this.$message({
                    message: "La facture a été dupliqué",
                    type: "success",
                  });

                  this.$router.push(`/sales/invoice/${res.data._id}`);
                }, 1.5 * 1000);
              })
              .catch((err) => {
                setTimeout(() => {
                  this.$message({
                    message: err.data.message,
                    type: "error",
                  });
                  instance.confirmButtonLoading = false;
                  instance.confirmButtonText = "Confirmer";
                }, 1.5 * 1000);
              });
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    handleValidInvoice() {
      this.validLoanding = true;
      validDocument(this.invoice._id, this.typeDoc)
        .then(() => {
          setTimeout(() => {
            this.$message({
              message: "La facture a été validée",
              type: "success",
            });
            this.invoice.status = "OPEND";
            this.validLoanding = false;
          }, 1.5 * 1000);
        })
        .catch((err) => {
          this.validLoanding = false;
          if (err.data.status === 400) {
            this.$message({
              message:
                "Veuillez renseigner les informations obligatoire et les articles",
              type: "warning",
            });
          } else if (err.data.status === 409 || err.data.status === 404) {
            this.$message({
              message: err.data.message,
              type: "error",
            });
          } else {
            this.$message({
              message: "Erreur serveur ! Merci de contacter l'administrateur",
              type: "error",
            });
          }
        });
    },
    handleAction(e) {
      if (e === "copy") {
        this.handleCopyInvoice();
      }
        if (e === "update") {
            this.$router.push({
                path: `update/${this.invoice._id}`,
            });
        }

      if (e === "purchase") {
          console.log("Hello")
        this.$router.push({
          path: `/purchases/bills/sale/${this.invoice._id}`,
        });

      }

      if (e === "cancel") {
        this.handleCancelInvoice();
      }
    },
    handleViewPDF() {
      this.dialogViewPDF = true;
      exportInvoicePDF(this.invoice._id)
        .then((res) => {
          const url = window.URL.createObjectURL(
            new Blob([res], { type: "application/pdf" })
          );
          this.urlPDF = url;
        })
        .catch((err) => {
          this.$message({
            message: err.data.message,
            type: "error",
          });
        });
    },
    handleClose() {
      this.dialogViewPDF = false;
    },
  },
};
</script>

<style></style>
