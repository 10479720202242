<template>
  <div>
    <div class="confirm">
      <el-dialog :visible="visible" :close-on-click-modal="false" width="30%">
        <div class="flex flex-col items-center justify-center">
          <div
            class="
              bg-yellow-100
              w-10
              h-10
              flex
              items-center
              justify-center
              rounded-full
              p-5
              text-xl
              mb-2
            "
          >
            <i class="el-icon-warning-outline text-yellow-500"></i>
          </div>
          <div class="font-semibold">
            Vous voulez vraiment annuler la transation N° {{ number }}?
          </div>
        </div>
        <div slot="footer" class="dialog-footer flex">
          <el-button class="w-1/2" @click="handleNo">Non</el-button>
          <el-button
            class="w-1/2"
            type="primary"
            :loading="loadingCancel"
            @click="cancelPayment"
            >OUI</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { cancelPaymentInvoice } from "@/api/sales2";
export default {
  name: "CANCELED-ANNULATION",
  props: {
    number: String,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingCancel: false,
    };
  },
  methods: {
    async cancelPayment() {
      this.loadingCancel = true;
      await cancelPaymentInvoice(this.number).then(() => {
        setTimeout(() => {
          this.loadingCancel = false;
          this.$emit("handleConfirme", false);
        }, 1.5 * 1000);
      });
    },
    handleNo() {
      this.$emit("handleNo", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
